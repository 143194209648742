import axios from 'axios';

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_CONFIG_API_URL,
});

export const PreferenceApiAxios = axios.create({
  baseURL: process.env.REACT_APP_PREFERENCE_API_URL,
});

export const IntegrationApiAxios = axios.create({
  baseURL: process.env.REACT_APP_INTEGRATION_API_URL,
});
