/* eslint-disable no-console */
import React from 'react';

import HeaderComponent from 'components/Header';
import { useLogout } from 'lib/hooks';
import { Top, Wrapper, Title, Message, Container } from './styled';

interface IProps {
  error: string;
}

const LoginButton = () => {
  const logOut = useLogout();
  return (
    <button type="button" onClick={() => logOut()}>
      login
    </button>
  );
};

const AuthErrorPage = ({ error }: IProps) => {
  return (
    <Wrapper>
      <HeaderComponent hideInfo />
      <Top style={{ height: '260px' }}>
        <Container>
          <Title>Something&apos;s wrong here.</Title>
          <Message>
            {error?.endsWith('.') ? error : `${error}.`} Please try to <LoginButton /> again later.
          </Message>
        </Container>
      </Top>
    </Wrapper>
  );
};

export default AuthErrorPage;
